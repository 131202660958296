const lightBoxImages = [
    {
        id:1,
        img:'assets/img/project/project-5.jpg'
    },
    {
        id: 2,
        img: 'assets/img/project/project-6.jpg'
    },
    {
        id: 3,
        img: 'assets/img/project/project-7.jpg'
    },
    {
        id: 4,
        img: 'assets/img/project/project-8.jpg'
    },
]

export default lightBoxImages;